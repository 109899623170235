export const networkConfig = {
  // 本地
  // serverUrl: "http://119.96.145.175:16809/",
  // 测试
  // serverUrl: "http://119.96.145.175:18921/",
  // serverUrl: "http://rpx.kplgnss.com:2402/",
  // personal: "http://119.96.80.54:29100/",
  // serverUrl: "http://119.96.230.169:39612/",
  // personal: " http://119.96.242.222:49400",
  serverUrl: window.location.pathname,
  personal: "https://s.kplgnss.com/sso/",
  // serverUrl: "http://119.96.176.142:18921/",
  // personal: "https://s.kplgnss.com/sso/",
  requestTimeout: 20000,
};
