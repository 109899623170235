<template>
  <div id="app">
    <MyHead v-if="isshow" />
    <ByHead v-else />
    <div class="mian">
      <router-view />
      <MyFooter v-if="isshow" />
      <ByFooter v-else />
    </div>
  </div>
</template>
<script>
import MyHead from "../src/components/Head.vue";
import ByHead from "../src/components/mHead.vue";
import MyFooter from "../src/components/footer.vue";
import ByFooter from "../src/components/mfooter.vue";
import { networkConfig } from "@/axios/networkConfig";

export default {
  name: "App",
  data() {
    return {
      screenWidth: document.body.clientWidth,
      isshow: true,
      footnone: true
    };
  },
  components: {
    MyHead, //pc头部
    ByHead, //手机端头部
    MyFooter, //pc端底部
    ByFooter //手机端底部
  },
  methods: {},
  mounted() {
    const that = this;

    window.onresize = () => {
      // window.screenWidth = document.body.clientWidth;
      // that.screenWidth = window.screenWidth;
      this.screenWidth = document.body.clientWidth;
      if (this.screenWidth < 992) {
        //这里写了个当值为1236时 就隐藏。
        // this.$router.push({ path: "/h5/home/index" });
        this.isshow = false;
      } else {
        this.isshow = true;
        // this.$router.push({ path: "/home/index" });
      }
      this.timer = true;
      let that = this;
      that.timer = false;
    };
  },
  watch: {
    // screenWidth(val) {
    //   if (!this.timer) {
    //     this.screenWidth = val;
    //     if (this.screenWidth < 992) {
    //       //这里写了个当值为1236时 就隐藏。
    //       // window.location.href = "https://www.kplgnss.com/h5/home/index";
    //     } else {
    //       // window.location.href = "https://www.kplgnss.com/";
    //     }
    //     this.timer = true;
    //     let that = this;
    //     setTimeout(function() {
    //       // 打印screenWidth变化的值
    //       // console.log(that.screenWidth); //观察完记得注释，不然卡死
    //       that.timer = false;
    //     }, 0);
    //   }
    // },
    $route(toPath, fromPath) {
      console.log(toPath, 5555);
      console.log(toPath.fullPath.split("/"));
      let tohref = toPath.fullPath.split("/");

      if (tohref[1] == "h5") {
        this.isshow = false;
      } else {
        this.isshow = true;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/style/fullpage.scss";
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
  font-size: 16px;
}
.mian {
  width: 100%;
  height: calc(100vh);
  // background: red;
  position: relative;
  > .bg_product {
    max-width: 1900px;
    min-width: 1440px;
    width: 100%;
  }
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.el-message {
  font-size: 16px;
}
</style>
