import Vue from "vue";
import Vuex from "vuex";
import { Password } from "@/axios/modules/officialwebsite";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    nikeName: "",
  },
  mutations: {
    set_nikeName(state, data) {
      // console.log(data, 222);
      state.nikeName = data;
    },
  },
  actions: {
    //   async changeNikeName({ commit }, fname) {
    //     const re = await Password("", "", "", "", fname);
    //     console.log(re);
    //   },
  },
  modules: {},
});
