<template>
  <div class="about_page">
    <!-- <sidebar></sidebar> -->
    <!--海报-->
    <div class="bg-about"></div>
    <!--公司图片-->
    <div class="company"></div>
    <!--荣誉-->
    <div class="honor">
      <h3>获取荣誉</h3>
      <p>HONOR</p>
      <div class="honor-img"></div>
    </div>

    <!--招聘-->
    <div class="recruit">
      <h3>加入我们</h3>
      <p>JOIN US</p>

      <div class="hot_input">
        <!-- 搜索框           :fetch-suggestions="querySearch" -->
        <div style="display: flex">
          <el-autocomplete
            class="inline-input"
            v-model="jallvalue"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            :trigger-on-focus="false"
            @change="searchposition"
            @keyup.enter="searchposition"
          ></el-autocomplete>

          <div class="hot">热门搜索</div>
        </div>

        <!-- 类别选择 -->
        <div style="display: flex">
          <div class="hot_text" style="color: #333">职位类别:</div>
          <el-select
            v-model="jkdsvalue"
            filterable
            placeholder="请选择"
            :options="options"
            @change="queryBtn"
          >
            <el-option
              v-for="item in options.select"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </div>
        <div style="display: flex">
          <div class="hot_text" style="color: #333">工作地点:</div>
          <el-select
            v-model="jcityvalue"
            filterable
            placeholder="请选择"
            :options="options"
            @change="queryBtn"
          >
            <el-option
              v-for="item in cities.select"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- 分割线 -->
      <!-- <el-divider></el-divider> -->
      <!-- 按钮 -->
      <!-- <div style="display: flex">
        <el-row v-for="(bottom, index) in bottoms" :key="index">
          <el-button type="info" plain>{{ bottom.label2 }}</el-button>
        </el-row>
      </div>-->

      <!-- 折叠面板 -->
      <div class="fold">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item v-for="(item, index) in posts" :key="index" class="template1">
            <template slot="title" name="1">
              <div
                class="template-title"
                @mouseover="activeName_item = index"
                :class="[
                  { active: activeName_item == index },
                  'template-title',
                ]"
              >
                <div class="template1_text">{{ item.jcity }}</div>
                <div class="template1_text">{{ item.jkds }}</div>
                <div class="template1_text">{{ item.jpost }}</div>
                <div class="template1_text">{{ item.jcity }}</div>
              </div>
            </template>

            <div class="title-name">
              <div>
                <h5>工作职责：</h5>
                <div class="title-name__text1" v-html="item.jres"></div>
              </div>
              <div style="margin-left: 77px">
                <h5>任职资格：</h5>
                <div class="title-name__text2" v-html="item.jqua"></div>
              </div>
              <el-button round>立即申请</el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <!-- 底部链接 -->
    <!-- <div class="fixed-footer">
      <MyFooter />
    </div>-->
  </div>
</template>

<script>
//import MyFooter from "../components/footer.vue";
//import Sidebar from "../components/sidebar.vue";
import { Offer, Config, Suggarea } from "../axios/modules/officialwebsite.js";
import { networkConfig } from "../axios/networkConfig";
export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      activeName_item: "",
      activeName: "1",
      // jkdsdata: "",
      // jcitydata: "",
      // jalldata: "",
      jcityvalue: "",
      jkdsvalue: "",
      jallvalue: "",

      restaurants: [{ value: "" }],

      query: [],

      options: [],
      cities: [],
      bottoms: [
        {
          label2: "市场",
          value3: "0"
        },
        {
          label2: "技术",
          value3: "1"
        },
        {
          label2: "前端",
          value3: "2"
        },
        {
          label2: "设计",
          value3: "3"
        },
        {
          label2: "运营",
          value3: "4"
        },
        {
          label2: "其他",
          value3: "5"
        }
      ],

      posts: []
    };
  },
  mounted() {
    this.Suggareadata("offer");
    this.Offerdata();
    this.Configdata("post");
  },
  methods: {
    // 职位信息接口
    async Offerdata(jkds, jcity, jall) {
      // console.log(jkds, jcity, jall, 222);
      let { code, data } = await Offer(jkds, jcity, jall);
      // console.log(data, 333);
      if (code == 0) {
        data.map(item => {
          this.posts.push(item);
        });
        // console.log(this.posts);
        // console.log(this.right_list_arr[0], 444);
        return;
      }
    },
    // 搜索查询接口
    async Configdata(local) {
      let { code, data } = await Config(local);
      // console.log(data.config, 5555);
      if (code == 0) {
        this.options = data.config.select[0];
        this.cities = data.config.select[1];

        // console.log(this.cities);
        // data.map((item) => {
        //   // this.posts.push(item);
        // });

        // console.log(this.right_list_arr[0], 444);
        return;
      }
    },
    // 模糊匹配
    async Suggareadata(area, key) {
      let { code, data } = await Suggarea(area, key);
      // console.log(data);
      if (code == 0) {
        // for (let i = 0; i <= data.length; i++) {
        //   this.restaurants[i].value = data[i];
        //   console.log(this.restaurants, 7777);
        // }
        this.restaurants = data.map(item => {
          return { value: item };
        });
        // console.log(this.restaurants, 3333);
        return;
      }
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    // 选择框
    queryBtn() {
      this.posts = [];
      if (this.jkdsvalue == "全部") {
        this.jkdsvalue = "";
      }
      this.Offerdata(this.jkdsvalue, this.jcityvalue, this.jallvalue);
    },
    searchposition() {
      // console.log(1111);
      this.posts = [];
      this.Offerdata(this.jkdsvalue, this.jcityvalue, this.jallvalue);
      // console.log(this.wtypevalue, this.wmodevalue, this.wallvalue, 2222);
    }
  },

  created() {},
  //components: {
  //  MyFooter,
  //  Sidebar,
  //},
  watch: {}
};
</script>
<style lang="scss" scoped>
.about_page {
  width: 100%;
}
.bg-about {
  margin-top: 100px;
  margin: 0 auto;
  width: 1900px;

  height: 874px;

  background-image: url("../assets/about/about2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 168px;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
}
.company {
  position: relative;
  width: 1900px;
  margin: 0 auto;
  height: 689px;
  background: url("../assets/about/gs_about.png") no-repeat;
  background-size: cover;
  margin-bottom: 188px;
}
.honor {
  width: 1320px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  //  background: red;
  > h3 {
    width: 220px;
    font-size: 40px;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
    letter-spacing: 15px;
    text-align: center;
    margin-bottom: 20px;
    // padding: 0.2rem;
  }
  > p {
    font-size: 20px;
    // font-weight: 400;
    color: #999595;
    letter-spacing: 10px;
    text-align: center;
    margin-bottom: 59px;
  }
  .honor-img {
    background: url("../assets/about/bg_honor.png") no-repeat;
    background-size: contain;
    width: 1320px;
    height: 584px;
    margin-bottom: 280px;
    // margin: 0 auto;
  }
}
.recruit {
  width: 1320px;
  height: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  flex-direction: column;
  h3 {
    width: 220px;
    font-size: 40px;
    font-weight: bold;
    line-height: 30px;
    color: #333333;
    letter-spacing: 15px;
    text-align: center;
    margin-bottom: 20px;
  }
  p {
    font-size: 20px;

    color: #999595;
    letter-spacing: 10px;
    text-align: center;
    margin-bottom: 59px;
  }
}
.fixed-footer {
  width: 100%;
  min-height: 1rem;
  background-color: rgba(51, 51, 51, 1);
  //position: fixed;
  //bottom: 0;
}
.el-collapse {
  width: 100%;
}
.el-collaps .el-collapse :focus {
  background-color: #004598;
  color: #ffffff;
}
.el-collapse-item {
  font-size: 18px;
  color: #ffff;
}
.hot_input {
  width: 1262px;
  height: 158px;
  background: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 29px;
  .el-input {
    width: 30vh;
  }

  .el-select {
    line-height: 40px;
  }
  .hot_text {
    padding-right: 10px;
    font-size: 16px;
    // font-family: Microsoft YaHei;
    font-weight: 40;

    line-height: 40px;
  }
  .hot {
    padding-left: 10px;
    font-size: 16px;
    line-height: 40px;
    // font-family: Microsoft YaHei;

    color: #ff6031;
  }
}
// 折叠面板
.fold {
  width: 1320px;
  height: 100%;
  min-height: 500px;
  margin-bottom: 10vh;
}
.template1 {
  width: 1320px;
  // background: #f9f9f9;

  .template-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    // background: red;

    // background: #f9f9f9;
  }
  .active.template-title {
    // background: #004598;
    // color: #fff;
  }
}
.title-name {
  display: flex;
  padding-left: 41px;
  align-items: center;
  // justify-content: space-between;
  .is-round {
    width: 96px;
    height: 32px;
    line-height: 32px;
    padding: 0;
  }
  h5 {
    font-size: 14px;

    padding: 19px 0;
  }
  .title-name__text1 {
    // width: ;
    width: 501px;
    height: 251px;
    // background: red;
  }
  .title-name__text2 {
    width: 506px;
    height: 251px;
  }
}
.template1_text {
  width: 190px;
  text-align: center;
  // padding: 90px;
}
.template1_texts {
}
::v-deep .el-input--small .el-input__inner {
  height: 40px;
}
::v-deep .el-collapse-item__header {
  height: 48px;
  font-size: 14px;
  line-height: 48px;
  // background: red;
}
::v-deep .is-active {
  background: #004598;
  color: #fff;
}
// el-collapse-item__header
::v-deep .el-collapse-item__header .is-active :hover {
  background-color: #8d9fb6;
}
::v-deep .el-collapse-item__content {
  font-size: 14px;
  background: linear-gradient(180deg, #ffffff 0%, #f2f6ff 100%);
  // margin: 1.5vw 0;
}
</style>