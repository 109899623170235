import { render, staticRenderFns } from "./newsxw3.vue?vue&type=template&id=1f7622d0&scoped=true"
import script from "./newsxw3.vue?vue&type=script&lang=js"
export * from "./newsxw3.vue?vue&type=script&lang=js"
import style0 from "./newsxw3.vue?vue&type=style&index=0&id=1f7622d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f7622d0",
  null
  
)

export default component.exports