<template>
  <div class="instrument-page">
    <sidebar></sidebar>
    <!-- 高精度定位服务 -->
    <div class="bg-instrument">
      <Head ref="carouselimg" />
      <!-- <div class="instrument-title">
            <p class="instrument-cn">高精度定位服务</p>
    
            <p class="instrument-an">High-precision positioning service</p>
            <p class="instrument-on"> c 
              高精度定位服务基于实时动态差分技术，提供更高
              精度的GNSS定位，实现从传统的道路级识别到更精准的
              车道级识别的提升，带来更精细的基于车道的导航体验。
            </p>
      </div>-->
    </div>
    <div class="instrument-product">
      <div class="instrument-cencnt">
        <div :id="listtitle.server" class="instrument-text">{{ listtitle.server }}</div>
        <div class="advantage">
          <ul :style="{ 'grid-template-columns': `repeat(${columns},1fr)` }">
            <li v-for="(item, index) in advantList" :key="index">
              <div
                class="instrument-traffc"
                :style="{
                      background: `url(${imgurl + item.list_pic}) no-repeat`,
                      'background-size': `contain`,
                    }"
              >
                <div></div>
                <div class="instrument-card">
                  <div class="instrument-card-left">
                    <p class="traffic-title">{{ item.ptitle }}</p>

                    <p class="traffic-text">{{ item.pdec }}</p>
                  </div>
                  <div class="instrument-subordinate">
                    <router-link type="text" :to=" item.path">
                      了解详情
                      <i class="el-icon-arrow-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="product-cencnt" v-for="(itemlist,index) in dataliset" :key="index">
        <div :id="listtitle[index]" class="product-text">{{ listtitle[index] }}</div>
        <div class="advantage_vu">
          <ul :style="{ 'grid-template-columns': `repeat(${columns},1fr)` }">
            <li v-for="(item, index) in itemlist" :key="index">
              <div class="img_box">
                <img v-lazy="imgurl + item.list_pic" alt />
              </div>
              <div class="product-title-text">
                <p class="title1">{{ item.ptitle }}</p>
                <p class="title2">{{ item.pdec }}</p>

                <router-link type="text" :to=" item.path">
                  了解详情
                  <i class="el-icon-arrow-right"></i>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 底部链接 -->
  </div>
</template>
    
    <script>
// import path from "path";
import Sidebar from "@/components/sidebar.vue";
import { get_pro_list, get_pro_title } from "@/axios/modules/tabulation.js";
import { get_prod_page } from "@/axios/modules/officialwebsite.js";
import { networkConfig } from "@/axios/networkConfig.js";
import Head from "./head.vue";

export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      val: false,
      covers: [],
      advantList: [],
      dataliset: [],
      columns: 4,
      listtitle: []
    };
  },
  components: {
    Head,
    Sidebar
  },
  mounted() {
    // console.log(this.imgurl);
    this.Get_prod_page();
    this.Newcarouseldata();
    this.Servelist().then(() => {
      this.jump();
    });
  },
  methods: {
    // 锚点定位

    jump() {
      setTimeout(() => {
        this.$el.querySelector("#" + this.$route.query.title).scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "center" // 上边框与视窗顶部平齐。默认值
        });
      }, 300);
      //   // console.log(2222);
      // }
    },
    async Get_prod_page(path) {
      let res = await get_prod_page();
      if (res.code == 0) {
        console.log(res,2222);
        this.$refs.carouselimg.imgList = res.data2;
        this.advantList = res.data1;
        this.dataliset = res.data;
        this.listtitle = res.list;
        return;
      }
    },
    // 首页轮播图
    async Newcarouseldata(path) {
      let { code, data } = await get_pro_title();
      if (code == 0) {
        // console.log(data, 333);
        // console.log(this.imgList);

        return;
      }
    },
    async Servelist() {
      let { code, data1, data, list } = await get_pro_list();
      // console.log(data, 222);
      if (code == 0) {
        // this.advantList = data1;
        // this.dataliset = data;
        // this.listtitle = list;

        // Object.keys(data).map(item => {
        //   console.log(item);
        // });
        return;
      }
    },
    oponcover() {
      this.val = "true";
      // console.log(this.val);
    },
    openprecision(index) {
      if (index == 0) {
        this.$router.push({ path: "/precisionpositioning/centimeter" });
      } else if (index == 1) {
        this.$router.push({ path: "/precisionpositioning/decimeter" });
      }
    }
  }
};
</script>
    <style lang="scss" scoped>
.bg-instrument {
  width: 100%;
  height: 873px;
  margin-top: 100px;
  margin-bottom: 95px;
  .bg-instrument__img {
    width: 1900px;
    margin: 0 auto;
    height: 100%;
    background-size: cover;
  }

  // .instrument-title {
  //   width: 6rem;
  //   position: absolute;
  //   background: transparent;
  //   right: 2.5rem;
  //   bottom: 1.3rem;
  //   text-align: right;

  //   color: #fff;

  //   .instrument-cn {
  //     font-size: 0.6rem;
  //     font-weight: bold;
  //     line-height: 60px;
  //     letter-spacing: 20px;
  //     color: rgba(255, 255, 255, 0.9);
  //     margin-bottom: 65px;
  //   }

  //   .instrument-an {
  //     margin-bottom: 20px;
  //     font-size: 17px;
  //     letter-spacing: 10px;
  //     margin-bottom: 65px;
  //   }
  //   .instrument-on {
  //     font-size: 20px;
  //     line-height: 46px;
  //     letter-spacing: 5px;
  //     text-align: left;
  //   }
  // }
}
.instrument-product {
  width: 1320px;
  margin: 0 auto;

  .instrument-cencnt {
    width: 100%;
    height: 100%;

    margin: 0 auto;
    .instrument-text {
      font-size: 35px;

      font-weight: bold;
      color: #333333;
      line-height: 28px;
      margin-bottom: 80px;
    }
  }
}
.advantage {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ul {
    display: grid;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 20px 10px;
    height: 100%;
    width: 100%;
    margin-bottom: 165px;
    li {
      list-style-type: none;
      width: 426px;
      display: flex;
      background-color: #fff;
      // align-items: center;
      // justify-content: center;
      flex-direction: column;

      .instrument-traffc {
        height: 240px;
        // border-radius: 20px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .instrument-card {
          width: 100%;
          height: 56px;
          // border-radius: 0px 0px 20px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: rgba(0, 0, 0, 0);
          .instrument-card-left {
            padding-left: 20px;

            width: 50%;
            height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .traffic-title {
            font-size: 14px;
          }
          .traffic-text {
            font-size: 12px;
            padding-top: 8px;
          }
          .instrument-subordinate {
            font-size: 18px;

            font-weight: 400;

            margin-right: 5%;
            cursor: pointer;
          }
        }
      }
    }
  }
}
li:hover {
  .instrument-card {
    background: rgba(0, 0, 0, 0.38);
    .traffic-title {
      color: #fff;
    }
    .traffic-text {
      color: #fff;
    }
  }
  .instrument-subordinate {
    a {
      color: #0090ff;
    }
  }
}
.product-cencnt {
  width: 1320px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .product-text {
    margin-bottom: 60px;
    font-size: 35px;
    color: #333333;
    line-height: 28px;
    font-weight: bold;
  }
}

.advantage_vu {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ul {
    display: grid;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    margin-bottom: 206px;

    li {
      list-style-type: none;
      width: 310px;
      height: 310px;
      display: flex;
      background-color: #fff;
      // align-items: center;
      // justify-content: center;
      flex-direction: column;
      margin-bottom: 25px;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      .img_box {
        height: 217px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 190px;
        }
      }
      .product-title-text {
        width: 100%;
        text-align: center;
        line-height: 40px;
        a {
          color: #0090ff;
        }

        .title1 {
          font-size: 30px;
          // font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 28px;
        }
        .title2 {
          font-size: 15px;
          // font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          // margin: 10px;
        }
      }
    }
  }
}
.router-link-active {
  text-decoration: none; //去除默认样式
}
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0);
}
</style>