<template>
  <div class="services_page">
    <sidebar></sidebar>
    <!--海报-->
    <div class="deskbao">
      <div class="desk">
        <img v-lazy="imgurl + psrcimg" alt />
        <!-- <div class="services">SUPPORT AND SERVICES</div>
        <div class="services_CN">服务与支持</div>-->
      </div>
    </div>
    <!--文件下载部分-->
    <div class="file">
      <h3 id="whydownload">资料下载</h3>
      <p class="enfile">DOWNLOAD INFORMATION</p>
      <!--选择器-->
      <div class="selectbao">
        <div class="select">
          <div class="upone">
            <div>
              <el-autocomplete
                class="inline-input"
                v-model="wallvalue"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @change="searchposition"
                @keyup.enter="searchposition"
              ></el-autocomplete>
              <!-- <el-autocomplete
                class="inline-input"
                v-model="wallvalue"
                :fetch-suggestions="querySearch"
                
                placeholder="请输入内容"
              ></el-autocomplete>-->
              <!-- <el-autocomplete
                style="width: 346px"
                class="res"
                v-model="wallvalue"
                prefix-icon="el-icon-search"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入类型"
                @input="searchposition"
                @keyup.enter="searchposition"
                size="small"
              ></el-autocomplete>-->

              <span class="hot">热门下载</span>
            </div>
            <!--搜索-->
            <div style="display: flex">
              <div class="hot_text" style="color: #333">类别:</div>
              <el-select
                v-model="wtypevalue"
                filterable
                placeholder="请选择"
                :options="options"
                @change="queryBtn"
              >
                <el-option
                  v-for="item in options.select"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </div>
            <div style="display: flex">
              <div class="hot_text" style="color: #333">型号：</div>
              <el-select
                v-model="wmodevalue"
                filterable
                placeholder="请选择"
                :options="options"
                @change="queryBtn"
              >
                <el-option
                  v-for="item in models.select"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </div>
          </div>

          <el-row>
            <!-- <a>
              <el-button>{{ menuList[0].name }} </el-button></a
            >
            <a>
              <el-button id="caiye">{{ menuList[1].name }}</el-button>
            </a>-->

            <!-- <el-button>{{ menuList[2].name }}</el-button>
            <el-button>{{ menuList[0].name }}</el-button>
            <el-button>{{ menuList[0].name }}</el-button>-->
          </el-row>
        </div>
      </div>

      <!--下载文件展示区域-->
      <div class="file_1">
        <!-- <div class="file_box">
          <div class="fl-box" ref="flbox">
            <div class="ppppp">
              <div class="fl-item-name">{{ cyname }}</div>
              <div class="fl-item">
                <div :id="'flItem' + index" v-for="(item, index) in flconlist" :key="index">
                  <div class="fl-item-con">
                    <div class="fl-item__icon"></div>
                    <span class="icontext">{{ item.wtitle }}</span>
                    <p class="fltext">
                      <span>{{ item.wform }}</span>
                      <span>{{ item.wdate }}</span>
                    </p>
                    <div class="btnitem-text">
                      <div class="btnitem-atext">
                        <i class="el-icon-download">
                          <a
                            :href="imgurl + item.wdown"
                            :download="item.wtitle"
                            class="fl-item-a"
                          >下载</a>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="file_box">
          <div class="qwer">
            <div class="fl-box" ref="flbox">
              <div class="fl-item-name1" id="shuomingshu">{{ smsname }}</div>
              <div class="fl-item">
                <div :id="'flItem' + index" v-for="(item, index) in instructions" :key="index">
                  <div class="fl-item-con">
                    <div class="fl-item__icon"></div>
                    <span class="icontext">{{ item.wtitle }}</span>
                    <p class="fltext">
                      <span>{{ item.wform }}</span>
                      <!-- <span>{{ item.wsize }}</span> -->
                      <span>{{ item.wdate }}</span>
                    </p>
                    <div class="btnitem-text">
                      <div class="btnitem-atext">
                        <i class="el-icon-download">
                          <a
                            :href="imgurl + item.wdown"
                            :download="item.wtitle"
                            class="fl-item-a"
                          >下载</a>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="file_box">
          <div class="qwer">
            <div class="fl-box" ref="flbox">
              <div class="fl-item-name1" id="shuomingshu">{{ spname }}</div>
              <div class="fl-item">
                <div :id="'flItem' + index" v-for="(item, index) in videdata" :key="index">
                  <div class="fl-item-con">
                    <div class="fl-item__icon"></div>
                    <span class="icontext">{{ item.wtitle }}</span>
                    <p class="fltext">
                      <span>{{ item.wform }}</span>
                      <!-- <span>{{ item.wsize }}</span> -->
                      <span>{{ item.wdate }}</span>
                    </p>
                    <div class="btnitem-text">
                      <div class="btnitem-atext">
                        <i class="el-icon-download">
                          <a
                            :href="imgurl + item.wdown"
                            :download="item.wtitle"
                            class="fl-item-a"
                          >下载</a>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="file_box">
          <!-- <div class="qwer">
            <div class="fl-box" ref="flbox">
              <div class="fl-item-name1" id="shuomingshu">软件</div>
              <div class="fl-item">
                <div
                  :id="'flItem' + index"
                  v-for="(item, index) in videdata"
                  :key="index"
                >
                  <div class="fl-item-con">
                    <div class="fl-item__icon"></div>
                    <span class="icontext">{{ item.wtitle }}</span>
                    <p class="fltext">
                      <span>{{ item.wform }}</span>
                      <span>{{ item.wsize }}</span>
                      <span>{{ item.wdate }}</span>
                    </p>
                    <div class="btnitem-text">
                      <div class="btnitem-atext">
                        <i class="el-icon-download">
                          <a
                            :href="
                              imgurl + item.wdown
                            "
                            :download="item.wtitle"
                            class="fl-item-a"
                            >下载</a
                          >
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
    <h3 id="whyquestion">常见问题</h3>

    <p class="enfile">COMMON PROBLEM</p>
    <SerVices ref="services" />
    <!-- 底部链接 -->
    <!-- <div class="fixed-footer">
      <MyFooter />
    </div>-->
  </div>
</template>

<script>
// import MyFooter from "../components/footer.vue";
// import { computed, components } from "vue";
import Sidebar from "@/components/sidebar.vue";
//import{search} from ele
import {
  Download,
  Config,
  Suggarea,
  get_com_prob
} from "@/axios/modules/officialwebsite.js";
import { networkConfig } from "@/axios/networkConfig";
import SerVices from "./service.vue";

export default {
  data() {
    return {
      imgurl: networkConfig.serverUrl + "upload/",
      cyname: "",
      smsname: "",
      spname: "",
      open: false,
      wtypevalue: "",
      wmodevalue: "",
      wallvalue: "",
      psrcimg: "",
      softwaredata: [],
      videdata: [],
      result: [],
      // url:'../assets/app.png',
      options: [],
      models: [],
      menuList: [{ name: "彩页" }, { name: "说明书" }, { name: "其他" }],
      // menuList: [{ name: "说明书" }, { name: "其他" }],

      flconlist: [],

      instructions: [],
      restaurants: [{ value: "" }]
    };
  },
  components: {
    // MyFooter,
    Sidebar,
    SerVices
  },
  created() {},
  mounted() {
    // this.restaurants = this.loadAll();
    this.Suggareadata("download");
    this.Downloaddata();
    this.Configdata("dload");
    this.jump();
    this.Det_com_prob();
  },
  methods: {
    // 常见问题
    async Det_com_prob() {
      let res = await get_com_prob();
      if (res.code == 0) {
        console.log(res);
        this.$refs.services.catalogue = res.data.catalogue;
        this.$refs.services.newcenter = res.data.newcenter;
      }
    },
    jump() {
      if (this.$route.query.text == 1) {
        setTimeout(() => {
          this.$el.querySelector("#whydownload").scrollIntoView({
            behavior: "smooth", // 平滑过渡
            block: "center" // 上边框与视窗顶部平齐。默认值
          });
        }, 300);

        // console.log(2222);
      } else if (this.$route.query.text == 2) {
        setTimeout(() => {
          this.$el.querySelector("#whyquestion").scrollIntoView({
            behavior: "smooth", // 平滑过渡
            block: "center" // 上边框与视窗顶部平齐。默认值
          });
        }, 300);
      }
    },
    // icon
    openicon() {
      this.open = true;
    },
    onopenicon() {
      this.open = false;
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    // 接口数据
    async Downloaddata(wtype, wmode, wall) {
      // console.log(wtype, wmode, wall);
      let { code, data } = await Download(wtype, wmode, wall);
      var moment = require("moment");

      if (code == 0) {
        this.psrcimg = data.fpage.psrc;
        data.list.map(item => {
          if (item.wtype == "彩页") {
            item.wdate = moment(Number(item.wdate) * 1000).format(
              "YYYY/MM/DD "
            );
            // item.wsize = (Number(item.wsize) / 1024).toFixed(2) + "M";
            this.flconlist.push(item);
            this.cyname = this.flconlist[0].wtype;
            // new Date(item.)
          } else if (item.wtype == "说明书") {
            item.wdate = moment(Number(item.wdate) * 1000).format(
              "YYYY/MM/DD "
            );

            item.wsize = (Number(item.wsize) / 1024).toFixed(2) + "M";
            this.instructions.push(item);
            this.smsname = this.instructions[0].wtype;
            // console.log(this.instructions, 2222);
          } else if (item.wtype == "软件") {
            item.wdate = moment(Number(item.wdate) * 1000).format(
              "YYYY/MM/DD "
            );

            item.wsize = (Number(item.wsize) / 1024).toFixed(2) + "M";
            this.softwaredata.push(item);
            // this.smsname=this.softwaredata[0].wtype
            // console.log(this.softwaredata, 2222);
          } else if (item.wtype == "视频") {
            item.wdate = moment(Number(item.wdate) * 1000).format(
              "YYYY/MM/DD "
            );

            item.wsize = (Number(item.wsize) / 1024).toFixed(2) + "M";
            this.videdata.push(item);
            // console.log(this.instructions, 2222);
            this.spname = this.videdata[0].wtype;
            console.log(this.spneme, 777777);
          }
        });
        // this.right_list_arr[0] = data.config;
        // console.log(this.right_list_arr[0], 444);
        return;
      }
    },
    // 搜索查询接口
    async Configdata(local) {
      let { code, data } = await Config(local);
      // console.log(data, 5555);
      if (code == 0) {
        this.options = data.config.select[0];
        this.models = data.config.select[1];

        // console.log(this.options, 11111);

        return;
      }
    },
    // 模糊匹配
    async Suggareadata(area, key) {
      let { code, data } = await Suggarea(area, key);
      // console.log(data);
      if (code == 0) {
        // for (let i = 0; i <= data.length; i++) {
        //   this.restaurants[i].value = data[i];
        //   console.log(this.restaurants, 7777);
        // }
        this.restaurants = data.map(item => {
          return { value: item };
        });
        // console.log(this.restaurants, 3333);
        return;
      }
    },
    queryBtn() {
      if (this.wtypevalue == "全部") {
        this.wtypevalue = "";
        // console.log(this.wtypevalue, this.wmodevalue, this.wallvalue, 2222);
      }
      if (this.wmodevalue == "全部") {
        this.wmodevalue = "";
      }
      this.flconlist = [];
      this.instructions = [];
      this.softwaredata = [];
      this.videdata = [];
      (this.cyname = ""),
        (this.smsname = ""),
        (this.spname = ""),
        this.Downloaddata(this.wtypevalue, this.wmodevalue, this.wallvalue);
    },
    searchposition() {
      if (this.wtypevalue == "全部") {
        this.wtypevalue = "";
        // console.log(this.wtypevalue, this.wmodevalue, this.wallvalue, 2222);
      }
      this.flconlist = [];
      this.instructions = [];
      this.softwaredata = [];
      this.videdata = [];
      (this.cyname = ""),
        (this.smsname = ""),
        (this.spname = ""),
        this.Downloaddata(this.wtypevalue, this.wmodevalue, this.wallvalue);
    }
    // console.log(1111);
  }
};
</script>
<style lang="scss" scoped>
.services_page {
  width: 1320px;
  margin: 0 auto;
}
.deskbao {
  // position: relative;
  height: 873px;
  margin-top: 100px;
}
.desk {
  width: 1900px;
  //  min-width: 1200px;
  height: 873px;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  > img {
    height: 873px;
    width: 100%;
  }
}

//   .services_CN {
//     font-size: 0.4rem;

//     font-weight: 400;
//     line-height: 1rem;
//     color: #ffffff;
//     letter-spacing: 50px;
//   }
// }
// 搜索框
.hot_text {
  width: 61px;
  padding-right: 10px;
  font-size: 20px;

  font-weight: 40;
  line-height: 40px;
}
.hot {
  padding-left: 10px;
  font-size: 16px;
  line-height: 40px;

  color: #ff6031;
}
.res {
}
//文件下载部分样式
h3 {
  font-size: 40px;

  font-weight: bold;
  // line-height: 100px;
  color: #333333;
  letter-spacing: 40px;
  text-align: center;
  margin-top: 110px;
}
//英文小标题
.enfile {
  font-size: 20px;
  margin-top: 20px;
  // font-weight: 400;
  color: #999595;
  letter-spacing: 20px;
  text-align: center;
  margin-bottom: 61px;
}
.file {
  //大标题

  //  选择器样式
  .selectbao {
    position: relative;
  }
  .select {
    width: 1320px;
    height: 250px;
    background: #f9f9f9;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    //上半部分盒子
    .upone {
      width: 88%;
      margin: 0 auto;
      // border-bottom: 1px solid rgba(112, 112, 112, 0.27);
      display: flex;
      justify-content: space-between;
      flex: 1;

      align-items: center;
      //热门下载
      .hot {
        font-size: 20px;

        font-weight: 400;
        // line-height: 0.6rem;
        color: #ff6031;
        // flex: 1;
        // margin-left: 0.5rem;
      }
      ::v-deep .el-input--small .el-input__inner {
        height: 40px;
      }
      //类目型号样式
      p {
        font-size: 20px;

        color: #333333;
      }
    }
  }
  .el-row {
    padding: 10px 0;
  }
  //快速选择按钮目录
  .fileitem {
    // padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //background: red;
  }
  //选项按钮
  .itemname {
    //flex: 1;
    width: 1rem;
    height: 0.3rem;
    background: #efefef;
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #333333;
    text-align: center;
    font-size: 0.14rem;
    line-height: 0.3rem;
    margin-right: 0.2rem;
  }
}
//产品说明书展示样式

.file_1 {
  // height: 500px;
  margin-bottom: 200px;
}
.file_box {
  margin: 0 auto;
  margin-bottom: 100px;
}
.fl-box {
}
//分类标题
.fl-item-name {
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
  padding: 20px;
}
.fl-item {
  display: flex;
  flex-wrap: wrap;
}
// 鼠标选中后
.fl-item-con:hover {
  background-color: #004598ff;
  z-index: 999;
  color: #fff;
  .fl-item__icon {
    background-image: url("../../icons/svg/pdfh.svg");
    // fill: red;

    // overflow: hidden;
    // // background: #004598ff;
    // filter: drop-shadow(#fff 0px 0);
  }
  .fltext {
    color: #fff;
  }
  .icontext {
    color: #fff;
  }
  .fl-item-a {
    color: #fff;
  }
  .btnitem-atext {
    border: 1px solid #fff;
  }
  .el-icon-download {
    color: #fff;
  }
  .flicon {
    background-image: url("../../icons/svg/pdfh.svg");
  }
}
.fl-item-a {
  text-decoration: none;
  color: #999595;
}

.fl-item-con {
  width: 300px;
  height: 400px;
  margin-bottom: 30px;
  background: #fafafa;
  border-radius: 15px;
  margin-right: 30px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  .fl-item__icon {
    width: 100px;
    height: 100px;
    background-image: url("../../icons/svg/pdf.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 50px auto;
  }
  //pdf图标

  //pdf图标
  .flicon {
    // padding: 50px 0;
  }
  //图标文件描述
  .icontext {
    font-size: 18px;

    font-weight: bold;
    line-height: 28px;
    color: #333333;
    text-align: center;
  }
  //文件类型大小时间
  .fltext {
    font-size: 14px;

    font-weight: 400;
    line-height: 28px;
    color: #999999;
    margin: 0 auto;
    margin-bottom: 40px;
    span {
      padding: 20px;
      //下载按钮
      // .el-button {
      //   width: 4vw;
      //   height: 1vw;

      //   margin: auto !important;
      //   margin-bottom: 0.2rem !important;
      // }
    }
    // 输入框文字大小样式
    ::v-deep .el-input__inner {
      font-size: 20px;
    }
  }
}
.fl-item-name1 {
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
  padding: 20px;
}
// 按钮
.el-row {
  margin: 0 0 0 5vh;
}
.btnitem-text {
  width: 120px;
  height: 80px;

  margin: 0 auto;
  .btnitem-atext {
    border-radius: 5px;
    border: 1px solid #999999;
    font-size: 16px;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
  }
  .el-icon-download {
    color: #999999;
  }
}
</style>
